html,
body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* This is needed to fix a Resize Observer bug on message infinite scroll in dev mode */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* Needed to override Salesforce styles which causes top contacts graph y axis text to be cut off */
#bar-chart-content svg {
  overflow: visible;
}
